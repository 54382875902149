import React from "react";

const DailyWins = () => {
  const monthlyWins = {
    August: [
      { id: 1, type: "image", src: "/200.png" },
      {
        id: 2,
        type: "text",
        title: "Daily Win",
        content: "Lorem ipsum, lorem ipsum lorem ipsum lorem ipsum",
      },
      { id: 3, type: "image", src: "/200.png" },
      { id: 4, type: "image", src: "/200.png" },
      { id: 5, type: "image", src: "/200.png" },
      {
        id: 6,
        type: "text",
        title: "Daily Win",
        content: "Lorem ipsum, lorem ipsum lorem ipsum lorem ipsum",
      },
      { id: 7, type: "image", src: "/200.png" },
    ],
    July: [
      { id: 8, type: "image", src: "/200.png" },
      {
        id: 9,
        type: "text",
        title: "Daily Win",
        content: "Lorem ipsum, lorem ipsum lorem ipsum lorem ipsum",
      },
    ],
  };

  return (
    <div className="space-y-6 md:space-y-8">
      {Object.entries(monthlyWins).map(([month, wins]) => (
        <section key={month} className="space-y-3 md:space-y-4">
          <div className="flex justify-between items-center">
            <h2 className="text-lg md:text-xl font-medium">{month}</h2>
            <button className="text-gray-600 hover:text-gray-800 transition-colors px-2 py-1 rounded-lg hover:bg-gray-100">
              View
            </button>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-3 md:gap-4">
            {wins.map((win) => (
              <div key={win.id} className="w-full">
                {win.type === "image" ? (
                  <div className="aspect-square bg-gray-200 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow">
                    <img
                      src={win.src}
                      alt="Win"
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                  </div>
                ) : (
                  <div className="aspect-square p-3 md:p-4 border rounded-lg hover:border-gray-300 transition-colors">
                    <h3 className="font-medium mb-1 md:mb-2 text-sm md:text-base">
                      {win.title}
                    </h3>
                    <p className="text-xs md:text-sm text-gray-600 line-clamp-4">
                      {win.content}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </section>
      ))}
    </div>
  );
};

export default DailyWins;
