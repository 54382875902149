import * as Dialog from "@radix-ui/react-dialog";
import { useFirebase } from "../firebase/context";

const DeleteModal = ({
  type,

  setDeletionGoalId,
  deletionGoalId,
  deletionTaskId,
  setDeletionTaskId,

  isDeleteModalOpen,
  setIsDeleteModalOpen,
}) => {
  const { removeGoalInFirebase, removeTaskInFirebase } = useFirebase();

  const handleDelete = () => {
    if (type === "goal") {
      deletionGoalId && removeGoalInFirebase(deletionGoalId);
      setIsDeleteModalOpen(false);
    }
    if (type === "task") {
      deletionTaskId && removeTaskInFirebase(deletionTaskId);
      setIsDeleteModalOpen(false);
    }
  };

  const handleClose = () => {
    setIsDeleteModalOpen(false);

    if (type === "goal") {
      setDeletionGoalId(null);
    }
    if (type === "task") {
      setDeletionTaskId(null);
    }
  };

  return (
    <Dialog.Root open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50" />
        <Dialog.Content className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg p-6 max-w-xs w-full">
          <Dialog.Title className="text-lg font-medium mb-4">
            Delete {type}
          </Dialog.Title>
          <Dialog.Description className="text-sm text-gray-600 mb-6">
            Are you sure you want to delete this {type}?
          </Dialog.Description>

          <div className="flex justify-end gap-3">
            <button
              onClick={handleClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md"
            >
              Confirm
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default DeleteModal;
