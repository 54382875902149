import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { ChevronDown } from "lucide-react";
import { useFirebase } from "../firebase/context";

function getMenuName(pathName) {
  const [, path] = pathName.split("/");
  if (path === "") return "Today";
  if (path === "goals") return "Goals";
  if (path === "daily-wins") return "Daily Wins";
  return "";
}

const Header = () => {
  const { toggleDrawer, setDrawerContent } = useFirebase();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();
  const path = getMenuName(location.pathname);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const clearDrawer = () => {
    toggleDrawer(false);
    setDrawerContent(null);
  };

  return (
    <div className="pb-9">
      {/* Header */}
      <header className="flex justify-between items-center mb-6">
        <div className="text-xl font-semibold">
          <Link to="/" className="flex gap-3 items-center">
            <img alt="logo" width={32} height={32} src="/logo.png" /> Daily Wins
          </Link>
        </div>
        <div className="flex items-center space-x-2">
          <div className="w-8 h-8 bg-blue-500 rounded-full overflow-hidden">
            <Link to="/login" className="cursor-pointer">
              <img
                src="/user.jpg"
                alt="User"
                className="w-full h-full object-cover"
              />
            </Link>
          </div>
        </div>
      </header>

      {/* Today Section */}
      {path && (
        <div
          className="relative w-full flex flex-col items-center"
          ref={dropdownRef}
        >
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="font-bold text-2xl">{path}</span>
            <ChevronDown
              className={`w-6 h-6 transition-transform duration-200 ${
                isOpen ? "rotate-180" : ""
              }`}
            />
          </div>

          {isOpen && (
            <div className="absolute top-full mt-2 w-48 bg-white rounded-lg shadow-lg overflow-hidden z-10">
              <ul className="list-none flex flex-col w-full">
                <li>
                  <Link
                    className="block px-4 py-3 text-center hover:bg-gray-50 cursor-pointer"
                    to="/"
                    onClick={() => {
                      setIsOpen(!isOpen);
                      clearDrawer();
                    }}
                  >
                    Today
                  </Link>
                </li>
                <li>
                  <Link
                    className="block px-4 py-3 text-center hover:bg-gray-50 cursor-pointer"
                    to="/goals"
                    onClick={() => {
                      setIsOpen(!isOpen);
                      clearDrawer();
                    }}
                  >
                    Goals
                  </Link>
                </li>
                <li>
                  <Link
                    className="block px-4 py-3 text-center hover:bg-gray-50 cursor-pointer"
                    to="/daily-wins"
                    onClick={() => {
                      setIsOpen(!isOpen);
                      clearDrawer();
                    }}
                  >
                    Daily Wins
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
