import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore/lite";
import { auth, db } from ".";
import { useFirebase } from "./context";

export const useAuthState = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setGoals, setTasks, setWins } = useFirebase();

  useEffect(() => {
    const fetchUserData = async (user) => {
      try {
        const userDocRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setGoals(data.goals || []);
          setTasks(data.tasks || []);
          setWins(data.wins || []);
        }

        setUser(user);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      fetchUserData(user);
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { user, loading };
};
