import React, { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  Plus,
  ChevronRight,
  MoreVertical,
  CheckCircle2,
  Circle,
  ArrowRight,
  ArrowRightCircle,
} from "lucide-react";
import EmptyTasksScreen from "./empty-tasks-screen";
import { useFirebase } from "../firebase/context";
import { isPastTaskUnfinished } from "../components/check-if-has-yesterday-task";
import DeleteModal from "./delete-modal";

const Check = ({ completed, color }) => {
  if (completed) {
    return (
      <CheckCircle2 style={{ color }} className="cursor-pointer" size={28} />
    );
  }
  return <Circle style={{ color }} className="cursor-pointer" size={28} />;
};

const TaskItem = ({ task, setIsDeleteModalOpen, setDeletionTaskId }) => {
  const { goals, updateTaskInFirebase, setTask, toggleDrawer } = useFirebase();
  const goal = goals.find((goal) => goal.id === task.goalId);

  const checkTask = () => {
    updateTaskInFirebase(task.id, { checked: !task.checked });
  };

  return (
    <div className="relative py-3">
      <div className="flex items-start">
        <div onClick={checkTask}>
          <Check completed={task.checked} color={goal.color} />
        </div>

        <div
          className="ml-2 flex-1 cursor-pointer"
          onClick={() => {
            setTask(task);
            toggleDrawer(true);
          }}
        >
          {task.taskStarter ? (
            <>
              <div className="ml-2 mb-1">
                <span className="font-medium">{task.taskStarter}</span>
              </div>
              <div className="flex">
                <ChevronRight size={24} className="text-gray-300 mr-1" />
                <span className="text-gray-400">{task.title}</span>
              </div>
            </>
          ) : (
            <div className="font-medium ml-2 mt-0.5">
              <span className="">{task.title}</span>
            </div>
          )}
        </div>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger className="-mt-1 ml-3 p-2 -mr-2 cursor-pointer outline-none">
            <MoreVertical className="text-gray-400" size={20} />
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className="min-w-[180px] bg-white rounded-lg shadow-lg border border-gray-100 py-1 -mt-2 mr-1 z-10"
              sideOffset={5}
              align="end"
            >
              <DropdownMenu.Item
                className="flex px-3 py-2 text-normal hover:bg-gray-100 cursor-pointer outline-none"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeletionTaskId(task.id);
                }}
              >
                Delete
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>

      {/* Mobile-friendly action menu */}
      {task.showActions && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-100 py-1 z-10">
          <button className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-2">
            <Plus size={14} />
            Add starter step
          </button>
        </div>
      )}
    </div>
  );
};

const getPosponedDays = (isSelected, postponedDays) => {
  if (postponedDays) {
    if (isSelected) {
      return postponedDays + 1;
    } else {
      return postponedDays;
    }
  }

  if (isSelected) {
    return 1;
  }

  return null;
};

const CarryOverTaskItem = ({ task, selectedTasks, setSelectedTasks }) => {
  const { goals } = useFirebase();
  const goal = goals.find((goal) => goal.id === task.goalId);
  const isSelected = selectedTasks[task.id];
  const postponedDays = getPosponedDays(isSelected, task.postponedDays);

  return (
    <div className="relative py-3">
      <div className="flex items-start">
        <div className="relative">
          {/* Pulse Animation Container */}
          <div
            className="relative w-7 h-7 flex items-center justify-center"
            onClick={() =>
              setSelectedTasks({
                ...selectedTasks,
                [task.id]: !isSelected,
              })
            }
          >
            {/* Main Circle with Arrow */}
            <div
              className="w-7 h-7 rounded-full border-2 flex items-center justify-center transition-all duration-300"
              {...(isSelected
                ? {
                    style: {
                      backgroundColor: goal.color,
                      borderColor: goal.color,
                    },
                  }
                : { style: { borderColor: goal.color } })}
            >
              <ArrowRight
                className={`w-4 h-4 transition-all duration-300 ${
                  selectedTasks[task.id] ? "text-white" : "text-gray-400"
                }`}
                {...(!isSelected && {
                  style: { color: goal.color },
                })}
              />
            </div>

            {/* Days Counter Badge */}
            {postponedDays && (
              <div className="absolute -top-2 -right-2 min-w-4 h-4 px-1 rounded-full bg-orange-500 text-white text-xs flex items-center justify-center z-10">
                {postponedDays}
              </div>
            )}
          </div>
        </div>

        <div className="ml-2 flex-1">
          {task.taskStarter ? (
            <>
              <div className="ml-2 mb-1">
                <span className="font-medium">{task.taskStarter}</span>
              </div>
              <div className="flex items-center">
                <ChevronRight size={24} className="text-gray-300 mr-1" />
                <span className="text-gray-400">{task.title}</span>
              </div>
            </>
          ) : (
            <div className="font-medium ml-2 mt-0.5">
              <span className="">{task.title}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const BeginToday = ({ setDeletionTaskId, setIsDeleteModalOpen }) => {
  const { hasYesterdayTask, tasks, updateAllTasksInFirebase } = useFirebase();
  const [selectedTasks, setSelectedTasks] = useState({});

  const selection = Object.entries(selectedTasks);
  const tasksToKeep = tasks
    .filter((task) => {
      const foundTask = selection.find(([key]) => task.id === key);
      if (!foundTask) return false;
      return foundTask[1];
    })
    .map((task) => {
      const postponedDays = task.postponedDays ? task.postponedDays + 1 : 1;

      return {
        ...task,
        postponedDays,
      };
    });

  const handleCarryOver = () => {
    updateAllTasksInFirebase(tasksToKeep);
  };

  if (hasYesterdayTask) {
    const unfinishedTasks = tasks.filter((task) => isPastTaskUnfinished(task));

    return (
      <>
        <div className="border-l-4 border-amber-500 pl-4 mb-6">
          <h3 className="text-lg font-medium mb-2">Unfinished tasks</h3>
          <p className="text-gray-600 text-sm">
            Please choose which tasks you’d like to continue working on.
            Unselected tasks will be removed.
          </p>
          <p className="text-gray-600 text-sm mt-2">
            Carry over days are highlited in orange.
          </p>
        </div>

        <div className="mb-6">
          {unfinishedTasks.map((task, i) => (
            <CarryOverTaskItem
              key={i}
              task={task}
              selectedTasks={selectedTasks}
              setSelectedTasks={setSelectedTasks}
            />
          ))}
        </div>

        <button
          className="w-full flex items-center justify-center space-x-2 px-4 py-3 text-sm text-blue-600 bg-blue-100 hover:bg-blue-200 rounded-lg transition-colors"
          onClick={handleCarryOver}
        >
          <span>
            Move {tasksToKeep.length}{" "}
            {tasksToKeep.length === 1 ? "task" : "tasks"} to Today
          </span>
          <ArrowRightCircle className="w-4 h-4" />
        </button>
      </>
    );
  }

  return (
    <div className="mb-6">
      {tasks.map((task, i) => (
        <TaskItem
          key={i}
          task={task}
          setDeletionTaskId={setDeletionTaskId}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      ))}
    </div>
  );
};

const Today = () => {
  const { tasks } = useFirebase();
  const [deletionTaskId, setDeletionTaskId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <div className="flex flex-col bg-white text-gray-800 font-sans">
      {/* Main Content */}
      {Boolean(tasks.length) ? (
        <main className="flex-grow">
          {/* Message */}
          <BeginToday
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            setDeletionTaskId={setDeletionTaskId}
          />
        </main>
      ) : (
        <EmptyTasksScreen />
      )}
      <DeleteModal
        type="task"
        deletionTaskId={deletionTaskId}
        setDeletionTaskId={setDeletionTaskId}
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
      />
    </div>
  );
};

export default Today;
