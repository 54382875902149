import React from "react";
import { ArrowRight, CheckCircle, Flag, Sparkles, Star } from "lucide-react";
import { useFirebase } from "../firebase/context";
import { Link } from "react-router-dom";

const EmptyTasksScreen = () => {
  const { goals } = useFirebase();

  if (!goals.length) {
    return (
      <div className="flex flex-col items-center justify-center p-6 bg-white text-center">
        {/* Custom illustration */}
        <div className="mb-8 relative">
          <div className="w-20 h-20 bg-blue-50 rounded-full flex items-center justify-center">
            <Flag className="w-10 h-10 text-blue-500" />
          </div>
          <div className="absolute -top-2 -right-2">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 2L15 9L22 9L16 14L19 21L12 17L5 21L8 14L2 9L9 9L12 2Z"
                fill="#FCD34D"
                stroke="#F59E0B"
                strokeWidth="1.5"
              />
            </svg>
          </div>
          <div className="absolute bottom-0 -left-4">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
              <circle cx="8" cy="8" r="4" fill="#93C5FD" />
            </svg>
          </div>
        </div>

        {/* Text content */}
        <h1 className="text-2xl font-bold mb-3 text-gray-900">
          Create your first goal
        </h1>
        <p className="text-gray-600 mb-8 max-w-sm">
          Click below to start setting and tracking your goals.
        </p>

        {/* CTA Button */}
        <Link
          to="/goals"
          className="flex items-center justify-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          Goals Page
          <ArrowRight className="w-4 h-4" />
        </Link>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center px-4 mt-8">
      <div className="text-center max-w-md">
        {/* Illustration */}
        <div className="mb-8 relative w-48 h-32 mx-auto">
          {/* Main circle with check */}
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="w-20 h-20 relative">
              <div className="absolute inset-0 bg-blue-50 rounded-full flex items-center justify-center">
                <CheckCircle className="w-10 h-10 text-blue-400" />
              </div>
              <Sparkles className="absolute -right-2 -top-2 w-6 h-6 text-yellow-400" />
            </div>
          </div>

          {/* Left side decorative elements */}
          <div className="absolute left-4 top-1/3">
            <div className="space-y-2">
              <div className="w-2 h-2 bg-emerald-100 rounded-full" />
              <div className="w-3 h-3 bg-blue-100 rounded-full" />
              <div className="w-2 h-2 bg-indigo-100 rounded-full" />
            </div>
          </div>

          {/* Right side decorative elements */}
          <div className="absolute right-4 top-1/3">
            <div className="space-y-2">
              <div className="w-3 h-3 bg-yellow-100 rounded-full" />
              <div className="w-2 h-2 bg-purple-100 rounded-full" />
              <Star className="w-4 h-4 text-blue-200" />
            </div>
          </div>

          {/* Extra floating elements */}
          <div className="absolute left-10 top-2">
            <div className="w-2 h-2 bg-yellow-200 rounded-full" />
          </div>
          <div className="absolute right-12 bottom-0">
            <div className="w-2 h-2 bg-emerald-200 rounded-full" />
          </div>
        </div>

        {/* Text content */}
        <h2 className="text-2xl font-semibold text-gray-900 mb-3">
          What's on your plate today?
        </h2>
        <p className="text-gray-600 mb-8">
          Break down your work into small tasks. Tap the + button to add your
          first task!
        </p>

        {/* Help link */}
        <div className="mt-2">
          <a
            href="/test"
            className="inline-flex items-center text-blue-600 hover:text-blue-700"
          >
            <span className="underline mr-1">How to organize your tasks</span>
            <span>?</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default EmptyTasksScreen;
