import { useEffect } from "react";
import { useFirebase } from "../firebase/context";

export const isPastTaskUnfinished = (task) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to start of today
  const updatedDate = new Date(task.updatedAt);

  return updatedDate < today && !task.checked;
};

const checkForYesterdayOrOlderTask = (tasks) =>
  tasks.some((task) => isPastTaskUnfinished(task));

const CheckIfHasYesterdayTask = ({ children }) => {
  const { setHasYesterdayTask, tasks } = useFirebase();

  useEffect(() => {
    setHasYesterdayTask(checkForYesterdayOrOlderTask(tasks));

    // Set interval to check every 5 minutes
    const fiveMin = 5 * 60 * 1000;
    const interval = setInterval(() => {
      checkForYesterdayOrOlderTask(tasks);
    }, fiveMin);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [tasks, setHasYesterdayTask]);

  return children;
};

export default CheckIfHasYesterdayTask;
