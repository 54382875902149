import React, { useEffect, useRef, useState } from "react";
import { useFirebase } from "../firebase/context";
import { Plus, X } from "lucide-react";

const FloatingButtonGoalPicker = () => {
  const [isMenuOpen, setMenuIsOpen] = useState(false);
  const { goals, toggleDrawer, drawerOpen, setTask } = useFirebase();
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const scrollRef = useRef(null);

  const checkScrollable = () => {
    if (scrollRef.current) {
      const { scrollHeight, clientHeight } = scrollRef.current;
      setIsScrollable(scrollHeight > clientHeight);
    }
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      // Add a small buffer (1px) for browser rounding differences
      const isBottom = scrollHeight - scrollTop - clientHeight < 1;
      setIsScrolledToBottom(isBottom);
    }
  };

  useEffect(() => {
    if (!isMenuOpen) {
      setIsScrolledToBottom(false);
      setIsScrollable(false);
    } else {
      checkScrollable();
    }
  }, [isMenuOpen]);

  return (
    <div className="fixed bottom-0 left-0 right-0 pointer-events-none">
      <div className="mx-auto max-w-2xl p-4 flex justify-end relative">
        <button
          onClick={() => {
            setMenuIsOpen(!isMenuOpen);
          }}
          className="pointer-events-auto w-14 h-14 bg-blue-500 hover:bg-blue-600 rounded-full flex items-center justify-center shadow-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
        >
          <div
            className={`absolute flex flex-col items-end bottom-20 right-4 origin-bottom-right bg-white rounded-lg shadow-lg transition-all duration-300 ease-in-out max-w-[350px] small-tablets:max-w-[90%] ${
              isMenuOpen ? "opacity-100" : "max-h-0 opacity-0"
            }`}
          >
            {isMenuOpen && (
              <div className="relative w-full">
                <div
                  ref={scrollRef}
                  onScroll={handleScroll}
                  className="max-h-64 overflow-y-auto rounded-lg"
                >
                  {goals.map((goal, index) => (
                    <button
                      key={index}
                      className="w-full px-5 py-4 hover:bg-gray-50 transition-colors text-gray-700 font-medium"
                      onClick={() => {
                        setTask({ goalId: goal.id });
                        toggleDrawer(!drawerOpen);
                        setMenuIsOpen(false);
                      }}
                    >
                      <div className="flex items-center gap-3">
                        <div className="flex-1 text-right">{goal.title}</div>
                        <div
                          className="w-4 h-4 rounded-full"
                          style={{ backgroundColor: goal.color }}
                        />
                      </div>
                    </button>
                  ))}
                </div>
                {isScrollable && !isScrolledToBottom && (
                  <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent pointer-events-none rounded-lg" />
                )}
              </div>
            )}
          </div>

          <div className="relative w-6 h-6">
            <span
              className={`absolute inset-0 flex items-center justify-center transition-all duration-300 ${
                isMenuOpen ? "opacity-0 rotate-180" : "opacity-100"
              }`}
            >
              <Plus className="w-8 h-8 text-white" />
            </span>
            <span
              className={`absolute inset-0 flex items-center justify-center transition-all duration-300 ${
                isMenuOpen ? "opacity-100 rotate-0" : "opacity-0 -rotate-180"
              }`}
            >
              <X className="w-8 h-8 text-white" />
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default FloatingButtonGoalPicker;
