import { createContext, useContext, useState } from "react";
import { auth, db } from ".";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore/lite";

const FirebaseContext = createContext();

export const useFirebase = () => {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error("useFirebase must be used within a FirebaseProvider");
  }
  return context;
};

export const FirebaseProvider = ({ children }) => {
  const [goals, setGoals] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [task, setTask] = useState(null);
  const [wins, setWins] = useState([]);
  const [drawerOpen, toggleDrawer] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);
  const [hasYesterdayTask, setHasYesterdayTask] = useState(false);

  const addGoalInFirebase = async (goal) => {
    // id: ID/String
    // title: String
    // color: String
    // updatedAt: String
    // status: String ??? maybe add in the future
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    setGoals([...goals, goal]);

    await updateDoc(userDocRef, {
      goals: arrayUnion(goal),
      updatedAt: new Date().toISOString(),
    });
  };

  const updateAllGoalsInFirebase = async (newGoals) => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    setGoals(newGoals);

    const userDocRef = doc(db, "users", user.uid);

    try {
      // Get the current document
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) throw new Error("User document not found");

      // Single operation to update the entire goals array
      await updateDoc(userDocRef, {
        goals: newGoals,
        updatedAt: new Date().toISOString(),
      });

      return { success: true };
    } catch (error) {
      console.error("Error updating goal:", error);
      throw error;
    }
  };

  const updateGoalInFirebase = async (goalId, updatedGoal) => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    try {
      // Get the current document
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) throw new Error("User document not found");

      // Get current goals array
      const currentGoals = docSnap.data().goals;
      if (!currentGoals || !currentGoals.length) {
        throw new Error("Goals SubDocument not found");
      }

      // Create new goals array with the updated goal
      const updatedGoals = currentGoals.map((goal) =>
        goal.id === goalId
          ? {
              ...goal,
              ...updatedGoal,
              updatedAt: new Date().toISOString(),
            }
          : goal
      );

      setGoals(updatedGoals);

      // Single operation to update the entire goals array
      await updateDoc(userDocRef, {
        goals: updatedGoals,
        updatedAt: new Date().toISOString(),
      });

      return { success: true };
    } catch (error) {
      console.error("Error updating goal:", error);
      throw error;
    }
  };

  const removeGoalInFirebase = async (goalId) => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    try {
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) throw new Error("User document not found");

      const currentGoals = docSnap.data().goals;
      if (!currentGoals || !currentGoals.length) {
        throw new Error("Goals SubDocument not found");
      }

      const updatedGoals = currentGoals.filter((goal) => goal.id !== goalId);

      setGoals(updatedGoals);

      await updateDoc(userDocRef, {
        goals: updatedGoals,
        updatedAt: new Date().toISOString(),
      });

      return { success: true };
    } catch (error) {
      console.error("Error removing goal:", error);
      throw error;
    }
  };

  const openDrawerWithGoal = (goalId) => {
    if (!goalId) return;

    toggleDrawer(true);

    const selectedGoal = goals.find((goal) => goal.id === goalId);

    setDrawerContent(selectedGoal);
  };

  const addTaskToFirebase = async () => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    const newTask = {
      ...task,
      id: String(new Date().getTime()),
      checked: false,
      updatedAt: new Date().toISOString(),
    };

    // cleanup
    setTasks([...tasks, newTask]);
    setTask(null);
    toggleDrawer(false);

    // firebase
    await updateDoc(userDocRef, {
      tasks: arrayUnion(newTask),
      updatedAt: new Date().toISOString(),
    });
  };

  const updateAllTasksInFirebase = async (newTasks) => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    try {
      const updatedAt = new Date().toISOString();
      const newTasksWithUpdateDate = newTasks.map((task) => ({
        ...task,
        updatedAt,
      }));
      setTasks(newTasksWithUpdateDate);

      // Get the current document
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) throw new Error("User document not found");

      // Single operation to update the entire goals array
      await updateDoc(userDocRef, {
        tasks: newTasksWithUpdateDate,
        updatedAt,
      });

      return { success: true };
    } catch (error) {
      console.error("Error updating goal:", error);
      throw error;
    }
  };

  const removeTaskInFirebase = async (taskId) => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    try {
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) throw new Error("User document not found");

      const currentTasks = docSnap.data().tasks;
      if (!currentTasks || !currentTasks.length) {
        throw new Error("Tasks SubDocument not found");
      }

      const updatedTasks = currentTasks.filter((task) => task.id !== taskId);

      setTasks(updatedTasks);

      await updateDoc(userDocRef, {
        tasks: updatedTasks,
        updatedAt: new Date().toISOString(),
      });

      return { success: true };
    } catch (error) {
      console.error("Error removing goal:", error);
      throw error;
    }
  };

  const updateTaskInFirebase = async (taskId, updatedTask) => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    try {
      // Get the current document
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) throw new Error("User document not found");

      // Get current tasks array
      const currentTasks = docSnap.data().tasks;
      if (!currentTasks || !currentTasks.length) {
        throw new Error("tasks SubDocument not found");
      }

      // Create new tasks array with the updated task
      const updatedTasks = currentTasks.map((task) =>
        task.id === taskId
          ? {
              ...task,
              ...updatedTask,
              updatedAt: new Date().toISOString(),
            }
          : task
      );

      setTasks(updatedTasks);

      // Single operation to update the entire tasks array
      await updateDoc(userDocRef, {
        tasks: updatedTasks,
        updatedAt: new Date().toISOString(),
      });

      return { success: true };
    } catch (error) {
      console.error("Error updating task:", error);
      throw error;
    }
  };

  const value = {
    goals,
    tasks,
    wins,
    setGoals,
    setTasks,
    setWins,
    addTaskToFirebase,
    updateAllTasksInFirebase,
    removeTaskInFirebase,
    updateTaskInFirebase,
    addGoalInFirebase,
    updateAllGoalsInFirebase,
    updateGoalInFirebase,
    removeGoalInFirebase,
    openDrawerWithGoal,
    toggleDrawer,
    drawerOpen,
    drawerContent,
    setDrawerContent,
    hasYesterdayTask,
    setHasYesterdayTask,
    task,
    setTask,
  };

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
