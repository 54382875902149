import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { GripVertical, MoreVertical } from "lucide-react";
import { useFirebase } from "../firebase/context";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import DeleteModal from "./delete-modal";
import EmptyGoalsScreen from "./empty-goals-screen";

const Goals = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletionGoalId, setDeletionGoalId] = useState(null);
  const { goals, updateAllGoalsInFirebase, openDrawerWithGoal } = useFirebase();

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(goals);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateAllGoalsInFirebase(items);
  };

  if (!goals.length) {
    return <EmptyGoalsScreen />;
  }

  return (
    <div>
      <div className="border-l-4 border-amber-500 pl-4 mb-6">
        <h3 className="text-lg font-medium mb-2">
          Your goals are listed in priority order
        </h3>
        <p className="text-gray-600 text-sm">
          Drag and drop to adjust their priority
        </p>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {goals.map((goal, index) => (
                <Draggable
                  key={String(goal.id)}
                  draggableId={String(goal.id)}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className="flex items-start justify-between"
                    >
                      <div className="flex items-start gap-4">
                        <div className="flex items-center gap-3">
                          <div {...provided.dragHandleProps} className="py-4">
                            <GripVertical
                              className="text-gray-400 cursor-grab"
                              size={20}
                            />
                          </div>
                        </div>
                        <span
                          className="font-medium py-3 cursor-pointer"
                          onClick={() => openDrawerWithGoal(goal.id)}
                        >
                          {goal.title}
                        </span>
                      </div>
                      <div className="flex items-center ml-4">
                        <div
                          className="w-4 h-4 rounded-full"
                          style={{ backgroundColor: goal.color }}
                        />
                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger className="py-4 cursor-pointer outline-none pl-3 p-2 -mr-2">
                            <MoreVertical className="text-gray-400" size={20} />
                          </DropdownMenu.Trigger>

                          <DropdownMenu.Portal>
                            <DropdownMenu.Content
                              className="min-w-[180px] bg-white rounded-lg shadow-lg border border-gray-100 -mt-2 mr-1 py-1 -mt-4 z-10"
                              sideOffset={5}
                              align="end"
                            >
                              <DropdownMenu.Item
                                className="flex px-3 py-2 text-normal hover:bg-gray-100 cursor-pointer outline-none"
                                onClick={() => {
                                  setIsDeleteModalOpen(true);
                                  setDeletionGoalId(goal.id);
                                }}
                              >
                                Delete
                              </DropdownMenu.Item>
                            </DropdownMenu.Content>
                          </DropdownMenu.Portal>
                        </DropdownMenu.Root>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <DeleteModal
        type="goal"
        setDeletionGoalId={setDeletionGoalId}
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        deletionGoalId={deletionGoalId}
      />
    </div>
  );
};

export default Goals;
