import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Goals from "./components/goals";
import Header from "./components/header";
import Today from "./components/today";
import DailyWins from "./components/daily-wins";
import AuthTabs from "./components/auth-tabs";
import { useAuthState } from "./firebase/useAuthState";
import Drawer from "./components/drawer";
import { FirebaseProvider } from "./firebase/context";
import CheckIfHasYesterdayTask from "./components/check-if-has-yesterday-task";

export const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuthState();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
};

function App() {
  return (
    <FirebaseProvider>
      <CheckIfHasYesterdayTask>
        <Router>
          <div className="max-w-2xl mx-auto w-full p-4 flex flex-col flex-grow">
            <Header />
            <Drawer />

            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Today />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/goals"
                element={
                  <ProtectedRoute>
                    <Goals />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/daily-wins"
                element={
                  <ProtectedRoute>
                    <DailyWins />
                  </ProtectedRoute>
                }
              />
              <Route path="/login" element={<AuthTabs />} />
            </Routes>
          </div>
        </Router>
      </CheckIfHasYesterdayTask>
    </FirebaseProvider>
  );
}

export default App;
