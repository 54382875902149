import { ArrowUp, ChevronDown, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useFirebase } from "../firebase/context";
import ColorPickerModal from "./modal-color-picker";

const GoalDrawer = () => {
  const [goalTitle, setGoalTitle] = useState("");
  const [selectedColor, setSelectedColor] = useState("#EF4444");
  const {
    addGoalInFirebase,
    updateGoalInFirebase,
    drawerOpen,
    drawerContent,
    toggleDrawer,
    setDrawerContent,
  } = useFirebase();
  const [isModalOpen, toggleModal] = useState(false);

  useEffect(() => {
    if (drawerContent) {
      setGoalTitle(drawerContent.title);
      setSelectedColor(drawerContent.color);
    }
  }, [drawerContent]);

  const handleSubmit = async () => {
    if (!goalTitle) {
      return;
    }

    if (drawerContent) {
      await updateGoalInFirebase(drawerContent.id, {
        title: goalTitle,
        color: selectedColor,
      });
    } else {
      await addGoalInFirebase({
        id: String(new Date().getTime()),
        title: goalTitle,
        color: selectedColor,
      });
    }

    setGoalTitle("");
    setSelectedColor("#EF4444");
    setDrawerContent(null);
    toggleDrawer(false);
  };

  if (!drawerOpen) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 w-full max-w-2xl mx-auto bg-white rounded-t-2xl shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] z-10">
      <div className="p-4 space-y-6">
        <div>
          <input
            autoFocus
            value={goalTitle}
            type="text"
            placeholder="Goal name"
            className="w-full mb-2 text-lg font-medium outline-none"
            onChange={(e) => setGoalTitle(e.target.value)}
          />
          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center gap-4">
              <button
                className="flex items-center gap-2 text-gray-400 bg-gray-100 rounded-lg px-2 py-1"
                onClick={toggleModal}
              >
                <div
                  className="w-3 h-3 rounded-full"
                  style={{ backgroundColor: selectedColor }}
                />
                <span>Color</span>
                <ChevronDown className="w-4 h-4" />
              </button>
            </div>
            <div className="flex items-center gap-3">
              <button
                className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-100 text-gray-500 hover:bg-gray-200"
                onClick={() => {
                  toggleDrawer(false);
                  setDrawerContent(null);
                }}
              >
                <X className="w-5 h-5" />
              </button>
              <button
                className="w-10 h-10 flex items-center justify-center rounded-full bg-blue-500 text-white hover:bg-blue-600"
                onClick={handleSubmit}
              >
                <ArrowUp className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <ColorPickerModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
      />
    </div>
  );
};

export default GoalDrawer;
