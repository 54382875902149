import FloatingActionButton from "./floating-button";
import GoalDrawer from "./goal-drawer";
import TaskDrawer from "./task-drawer";
import { useAuthState } from "../firebase/useAuthState";
import { useFirebase } from "../firebase/context";
import { useLocation } from "react-router-dom";

const Drawer = () => {
  const { user } = useAuthState();
  const { drawerOpen } = useFirebase();
  const location = useLocation();

  if (!user) return;

  if (!drawerOpen) {
    return <FloatingActionButton />;
  }

  if (location.pathname === "/goals") {
    return <GoalDrawer />;
  }

  if (location.pathname === "/") {
    return <TaskDrawer />;
  }
};

export default Drawer;
